










































































































import currencyFilter from "@/filters/currency.filter";
import quantityFilter from "@/filters/quantity.filter";
import { InvoiceApSummaryResponseDto } from "@/models/interface/InvoiceApSummary.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SummaryModal extends Vue {
  @Prop({ required: true, type: Boolean }) visible!: boolean;
  @Prop({
    required: false,
    type: Object,
  })
  dataView!: InvoiceApSummaryResponseDto;

  columns = [
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom",
      width: 100,
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      align: "right",
      width: 75,
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      align: "right",
      width: 150,
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_base_amount"),
      dataIndex: "baseAmount",
      align: "right",
      width: 150,
      customRender: text => currencyFilter(text),
    },
  ];

  handleOk(): void {
    this.$emit("ok");
  }
}
